























































import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import clienteModule from "@/store/modules/cliente-module";
import { cliente } from "@/shared/dtos/cliente";
import { UtilsDate } from "@/utils/utils-date";

@Component({
  components: {},
})
export default class ClientesFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: cliente = new cliente();

  public async OnCreated() {
    if (this.getparamId().HayParametro) {
      await clienteModule.getcliente(this.getparamId().Parametro);
      this.item_pantalla = clienteModule.cliente;
    } else {
      this.item_pantalla.licencia_inicio = new Date();
      this.item_pantalla.licencia_fin = UtilsDate.AddDias(new Date(), 365);
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    /*Apartado Datos de contacto*/
    this.AddFormItemDataDefaultSlot(
      undefined,
      "Datos de contacto de la organización",
      "a_contacto",
      20
    ).Dimesiones(12, 12, 12);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.telefono,
      "Teléfono",
      "telefono",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.correo,
      "Correo",
      "correo",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 4)
      .CrearValidacion("email");

    /*Apartado Datos de licencia*/
    this.AddFormItemDataDefaultSlot(
      undefined,
      "Datos de licencia",
      "a_licencia",
      20
    ).Dimesiones(12, 12, 12);
    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.licencia_inicio),
      "Fecha licencia inicio",
      "licencia_inicio"
    ).isRequired();

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.licencia_fin),
      "Fecha licencia fin",
      "licencia_fin"
    ).isRequired();

    /* this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre_usuario,
      "Nombre usuario",
      "nombre_usuario",
      60
    )
      .isRequired(this.EstoyCreado)
      .Dimesiones(12, 6, 4)
      .isVisible(this.EstoyCreado);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.contrasena_usuario,
      "Contraseña usuario",
      "contrasena_usuario",
      60
    )
      .isRequired(this.EstoyCreado)
      .Dimesiones(12, 6, 4)
      .isVisible(this.EstoyCreado);*/

    /*Apartado Datos adicionales*/
    this.AddFormItemDataDefaultSlot(
      undefined,
      "Datos adicionales",
      "a_adicionales",
      20
    ).Dimesiones(12, 12, 12);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.datos_adicionales,
      "Datos adicionales",
      "datos_adicionales"
    ).CreateTextArea();
  }

  public Insertar(object: cliente) {
    clienteModule.guardarcliente(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: cliente) {
    clienteModule.modificarcliente(object).then(() => {
      this.AllSaveOk();
    });
  }
}
